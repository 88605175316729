<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import HeroSection from '../components/HeroSection.vue'
import UnevenLayout from '../components/layout/UnevenLayout.vue'
import InternalLink from '../components/InternalLink.vue'

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'HomePage',
    metaInfo() {
        const title = 'Isabella Kirkland';
        
        const description = 'Isabella Kirkland’s work presents a seamless blend of art and science with attention to the techniques of the Dutch Old Masters, as well as her meticulous approach to her subject through a scientific lens.';

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title, description);
    },
    components: {
        GlobalHeader,
        GlobalFooter,
        HeroSection,
        UnevenLayout,
        InternalLink,

        // Carousel Components
        Carousel, 
        Slide
    }
}
</script>

<template>
  <div id="home-page">
    <GlobalHeader/>
    <carousel :items-to-show="1" :wrap-around="true" :autoplay="7000" :transition="1200">
      <slide :key="1">
        <HeroSection
          :image="require('@/assets/images/home-page/hero-taxa.jpg')"
          header="Detail of Gone from the Taxa series"
          caption="" 
          button=""
          route="/collections/taxa"
        />
      </slide>
      <slide :key="2">
        <HeroSection
          :image="require('@/assets/images/home-page/hero-nova.jpg')"
          header="Detail of Forest Floor from the Nova series"
          caption="" 
          button=""
          route="/collections/nova"
        />
      </slide>
      <slide :key="3">
        <HeroSection
          :image="require('@/assets/images/home-page/hero-large.jpg')"
          header="Detail of Nudibranchia"
          caption="" 
          button=""
          route="/collections/outdoor-still-life/paintings/nudibranchia"
        />
      </slide>
    </carousel>
    <div class="content pad">
      <UnevenLayout>
        <template #left>
            <!-- <span class="sub-heading">Paintings</span>
            <h3>Explore the paintings</h3> -->
            <!-- <p>Immerse yourself in a world of captivating colors and profound emotions as you explore Isabella's extraordinary collection.</p> -->
            <InternalLink route="CollectionsPage" text="Paintings"/>
        </template>
        <template #right>
          <router-link :to="{ name: 'CollectionsPage' }">
            <img src="@/assets/images/home-page/discover-home-tn.jpg"/>
          </router-link>
        </template>
      </UnevenLayout>
      <UnevenLayout :inverted="true">
        <template #left>
          <router-link :to="{ name: 'PaintingKeysPage' }">
            <img src="@/assets/images/home-page/species-home-tn.jpg"/>
          </router-link>
        </template>
        <template #right>
            <!-- <span class="sub-heading">Discover</span>
            <h3>Species Keys</h3> -->
            <!-- <p>Unlock the beauty and meaning behind each brushstroke with our species Keys.</p> -->
            <InternalLink route="PaintingKeysPage" text="Species Keys"/>
        </template>
      </UnevenLayout>
      <UnevenLayout>
        <template #left>
            <!-- <span class="sub-heading">Articles and Publications</span>
            <h3>Writings &amp; Talks</h3> -->
            <!-- <p>Delve deeper into the artistic realm of Isabella through our curated collection of articles and publications.</p> -->
            <InternalLink route="WritingsAndTalksPage" text="Writings &amp; Talks"/>
        </template>
        <template #right>
          <router-link :to="{ name: 'WritingsAndTalksPage' }">
            <img src="@/assets/images/home-page/talks-home-tn.jpg"/>
          </router-link>
        </template>
      </UnevenLayout>
      <UnevenLayout :inverted="true">
        <template #left>
            <router-link :to="{ name: 'ContactPage' }">
              <img src="@/assets/images/home-page/taxa-prints-tn.png"/>
            </router-link>
        </template>
        <template #right>
            <InternalLink route="ContactPage" text="Signed and numbered Taxa series"/>
        </template>
      </UnevenLayout>
      <UnevenLayout>
        <template #left>
            <!-- <h3>Process Time-lapse</h3> -->
            <!-- <p>Discover Isabella's creative process through a short time-lapse.</p> -->
            <InternalLink route="ProcessPage" text="Process Time-lapse"/>
        </template>
        <template #right>
            <router-link :to="{ name: 'ProcessPage' }">
              <img src="@/assets/images/home-page/time-home-tn.jpg"/>
            </router-link>
        </template>
      </UnevenLayout>
    </div>
    
    <GlobalFooter/>
  </div>
</template>

<style>
@media screen and (max-width: 768px) {
  #home-page h2 {
    font-size: 2em;
  }
  #home-page .link .chevron {
    transform: scale(0.6);
    top: 0.1em;
    right: -0.5em;
  }
  #home-page .uneven-layout .link {
    margin-top: 0;
    padding-top: 0;
  }
  #home-page .uneven-layout .right,
  #home-page .uneven-layout.inverted .left {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  #home-page .uneven-layout.inverted .right {
    grid-column-start: initial;
    grid-row-start: initial;
  }
}
</style>