export default {
    'ascendant': {
        1: {
            'x': 0.514,
            'y': 0.514
        },
        2: {
            'x': 0.384378126042014,
            'y': 0.766875
        },
        3: {
            'x': 0.6967322440813605,
            'y': 0.2150625
        },
        4: {
            'x': 0.5875291763921308,
            'y': 0.58375
        },
        5: {
            'x': 0.2894298099366455,
            'y': 0.421125
        },
        6: {
            'x': 0.7672557519173058,
            'y': 0.5508125
        },
        7: {
            'x': 0.47265755251750585,
            'y': 0.7295625
        },
        8: {
            'x': 0.4502334111370457,
            'y': 0.277125
        },
        9: {
            'x': 0.542,
            'y': 0.669
        },
        10: {
            'x': 0.41580526842280763,
            'y': 0.5651875
        },
        11: {
            'x': 0.834,
            'y': 0.699
        },
        12: {
            'x': 0.04893297765921974,
            'y': 0.83175
        },
        13: {
            'x': 0.3406968989663221,
            'y': 0.383875
        },
        14: {
            'x': 0.6492997665888629,
            'y': 0.39825
        },
        15: {
            'x': 0.7813437812604201,
            'y': 0.503125
        },        
        16: {
            'x': 0.2962654218072691,
            'y': 0.4964375
        },
        17: {
            'x': 0.6776425475158386,
            'y': 0.4836875
        },
        18: {
            'x': 0.6925641880626876,
            'y': 0.525125
        },
        19: {
            'x': 0.6393797932644215,
            'y': 0.6058125
        },
        20: {
            'x': 0.7802600866955652,
            'y': 0.3551875
        },
        21: {
            'x': 0.9089696565521841,
            'y': 0.4906875
        },
        22: {
            'x': 0.9533177725908636,
            'y': 0.364375
        },
        23: {
            'x': 0.9533177725908636,
            'y': 0.623375
        },
        24: {
            'x': 0.9448982994331444,
            'y': 0.695125
        },
        25: {
            'x': 0.9571523841280427,
            'y': 0.766875
        },
        26: {
            'x': 0.9280593531177059,
            'y': 0.865125
        },
        27: {
            'x': 0.7749249749916639,
            'y': 0.7691875
        },
        28: {
            'x': 0.8316105368456153,
            'y': 0.89125
        },
        29: {
            'x': 0.7757585861953985,
            'y': 0.93975
        },
        30: {
            'x': 0.49616538846282093,
            'y': 0.901875
        },
        31: {
            'x': 0.6178726242080693,
            'y': 0.85475
        },
        32: {
            'x': 0.6684728242747583,
            'y': 0.6870625
        },
        33: {
            'x': 0.5300933644548182,
            'y': 0.704625
        },
        34: {
            'x': 0.5926975658552851,
            'y': 0.766875
        },
        35: {
            'x': 0.5492664221407135,
            'y': 0.93975
        },
        36: {
            'x': 0.3226908969656552,
            'y': 0.93975
        },
        37: {
            'x': 0.19906635545181728,
            'y': 0.901875
        },
        38: {
            'x': 0.2373291097032344,
            'y': 0.811125
        },
        39: {
            'x': 0.044514838279426476,
            'y': 0.8875
        },
        40: {
            'x': 0.14088029343114372,
            'y': 0.7778125
        },
        41: {
            'x': 0.035,
            'y': 0.651
        },
        42: {
            'x': 0.2909303101033678,
            'y': 0.69025
        },
        43: {
            'x': 0.384378126042014,
            'y': 0.6285
        },
        44: {
            'x': 0.2770923641213738,
            'y': 0.5584375
        },
        45: {
            'x': 0.41580526842280763,
            'y': 0.408
        },
        46: {
            'x': 0.4609869956652217,
            'y': 0.46775
        },
        47: {
            'x': 0.09794931643881294,
            'y': 0.5584375
        },
        48: {
            'x': 0.03209403134378126,
            'y': 0.4964375
        },
        49: {
            'x': 0.15996998999666556,
            'y': 0.476375
        },
        50: {
            'x': 0.22199066355451819,
            'y': 0.4045625
        },
        51: {
            'x': 0.1727242414138046,
            'y': 0.281125
        },
        52: {
            'x': 0.2909303101033678,
            'y': 0.331375
        },
        53: {
            'x': 0.3966322107369123,
            'y': 0.3265
        },
        54: {
            'x': 0.5492664221407135,
            'y': 0.281125
        },
        55: {
            'x': 0.640,
            'y': 0.306
        },
        56: {
            'x': 0.9341447149049683,
            'y': 0.176
        },
        57: {
            'x': 0.7419139713237746,
            'y': 0.197875
        },
        58: {
            'x': 0.6370456818939647,
            'y': 0.0738125
        },
        59: {
            'x': 0.46515505168389465,
            'y': 0.197875
        },
        60: {
            'x': 0.3292764254751584,
            'y': 0.051375
        },
        61: {
            'x': 0.3717905968656219,
            'y': 0.156875
        },
        62: {
            'x': 0.18372790930310104,
            'y': 0.176
        },
        63: {
            'x': 0.08569523174391463,
            'y': 0.06575
        },
        64: {
            'x': 0.7228242747582527,
            'y': 0.0945
        },
        65: {
            'x': 0.5175891963987996,
            'y': 0.045125
        },
        66: {
            'x': 0.5492664221407135,
            'y': 0.8030625
        },
        67: {
            'x': 0.2770923641213738,
            'y': 0.6345
        },
        68: {
            'x': 0.5492664221407135,
            'y': 0.561
        },
        69: {
            'x': 0.42264088029343116,
            'y': 0.52975
        },
        70: {
            'x': 0.6178726242080693,
            'y': 0.447625
        },
        71: {
            'x': 0.06368789596532178,
            'y': 0.7439375
        },
        72: {
            'x': 0.4502334111370457,
            'y': 0.0738125
        },
        73: {
            'x': 0.6178726242080693,
            'y': 0.5395
        },
        74: {
            'x': 0.8422807602534178,
            'y': 0.302625
        }
    },
    'back': {
        1: {
            'x': 0.145,
            'y': 0.575
        },
        2: {
            'x': 0.243993993993994,
            'y': 0.1385
        },
        3: {
            'x': 0.136,
            'y': 0.309
        },
        4: {
            'x': 0.25508842175508845,
            'y': 0.311125
        },
        5: {
            'x': 0.3521855188521855,
            'y': 0.336125
        },
        6: {
            'x': 0.4994994994994995,
            'y': 0.203375
        },
        7: {
            'x': 0.09142475809142475,
            'y': 0.46375
        },
        8: {
            'x': 0.3688688688688689,
            'y': 0.50475
        },
        9: {
            'x': 0.613,
            'y': 0.366
        },
        10: {
            'x': 0.16608274941608275,
            'y': 0.654375
        },
        11: {
            'x': 0.1494828161494828,
            'y': 0.747625
        },
        12: {
            'x': 0.20737404070737403,
            'y': 0.859875
        },
        13: {
            'x': 0.08291624958291625,
            'y': 0.867375
        },
        14: {
            'x': 0.09943276609943276,
            'y': 0.947625
        },
        15: {
            'x': 0.17400734067400733,
            'y': 0.947625
        },
        16: {
            'x': 0.26059392726059394,
            'y': 0.960125
        },
        17: {
            'x': 0.2772772772772773,
            'y': 0.918
        },
        18: {
            'x': 0.4137470804137471,
            'y': 0.874875
        },
        19: {
            'x': 0.4137470804137471,
            'y': 0.951625
        },
        20: {
            'x': 0.3153153153153153,
            'y': 0.867375
        },
        21: {
            'x': 0.337,
            'y': 0.758
        },
        22: {
            'x': 0.34851518184851515,
            'y': 0.611
        },
        23: {
            'x': 0.42308975642308977,
            'y': 0.5735
        },
        24: {
            'x': 0.5161828495161829,
            'y': 0.569125
        },
        25: {
            'x': 0.5990990990990991,
            'y': 0.529625
        },
        26: {
            'x': 0.48281614948281615,
            'y': 0.629375
        },
        27: {
            'x': 0.47505839172505837,
            'y': 0.46675
        },
        28: {
            'x': 0.6574908241574908,
            'y': 0.46675
        },
        29: {
            'x': 0.45845845845845845,
            'y': 0.756125
        },
        30: {
            'x': 0.7055388722055389,
            'y': 0.68825
        },
        31: {
            'x': 0.593,
            'y': 0.817
        },
        32: {
            'x': 0.6628294961628295,
            'y': 0.8225
        },
        33: {
            'x': 0.5990990990990991,
            'y': 0.960125
        },
        34: {
            'x': 0.6036036036036037,
            'y': 0.88725
        },
        35: {
            'x': 0.7534200867534201,
            'y': 0.882875
        },
        36: {
            'x': 0.7867033700367033,
            'y': 0.82875
        },
        37: {
            'x': 0.9425258591925259,
            'y': 0.90275
        },
        38: {
            'x': 0.9651317984651318,
            'y': 0.847375
        },
        39: {
            'x': 0.9259259259259259,
            'y': 0.81625
        },
        40: {
            'x': 0.8992325658992326,
            'y': 0.735125
        },
        41: {
            'x': 0.9259259259259259,
            'y': 0.586
        },
        42: {
            'x': 0.7160493827160493,
            'y': 0.49225
        },
        43: {
            'x': 0.5575575575575575,
            'y': 0.441875
        },
        44: {
            'x': 0.7101267934601267,
            'y': 0.3440625
        },
        45: {
            'x': 0.8825492158825492,
            'y': 0.423875
        },
        46: {
            'x': 0.8732065398732065,
            'y': 0.321125
        },
        47: {
            'x': 0.7494160827494161,
            'y': 0.273125
        },
        48: {
            'x': 0.6995328661995329,
            'y': 0.079625
        }
    },
    'co-existants': {
        1: {
            'x': 0.041,
            'y': 0.028
        },
        2: {
            'x': 0.0151875,
            'y': 0.10938932697597653
        },
        3: {
            'x': 0.0711875,
            'y': 0.12864478268842838
        },
        4: {
            'x': 0.12375,
            'y': 0.03686044379240785
        },
        5: {
            'x': 0.1875625,
            'y': 0.877590317256556
        },
        6: {
            'x': 0.0270625,
            'y': 0.3252338162479369
        },
        7: {
            'x': 0.2204375,
            'y': 0.18833669539702916
        },
        8: {
            'x': 0.1941875,
            'y': 0.02127269392994682
        },
        9: {
            'x': 0.247,
            'y': 0.099
        },
        10: {
            'x': 0.2308125,
            'y': 0.02127269392994682
        },
        11: {
            'x': 0.3645,
            'y': 0.04061984228864845
        },
        12: {
            'x': 0.341375,
            'y': 0.11764166513845589
        },
        13: {
            'x': 0.4170625,
            'y': 0.2411516596368971
        },
        14: {
            'x': 0.47525,
            'y': 0.11397395928846507
        },
        15: {
            'x': 0.5636875,
            'y': 0.22189620392444526
        },
        16: {
            'x': 0.6189375,
            'y': 0.13698881349715752
        },
        17: {
            'x': 0.6856875,
            'y': 0.04373739226114066
        },
        18: {
            'x': 0.6945625,
            'y': 0.22693929946818264
        },
        19: {
            'x': 0.7208125,
            'y': 0.2797542637080506
        },
        20: {
            'x': 0.773625,
            'y': 0.22693929946818264
        },
        21: {
            'x': 0.832,
            'y': 0.037
        },
        22: {
            'x': 0.8891875,
            'y': 0.13698881349715752
        },
        23: {
            'x': 0.9540625,
            'y': 0.12461030625343847
        },
        24: {
            'x': 0.9671875,
            'y': 0.3160645516229598
        },
        25: {
            'x': 0.8629375,
            'y': 0.2967174032642582
        },
        26: {
            'x': 0.92275,
            'y': 0.33678708967540805
        },
        27: {
            'x': 0.9540625,
            'y': 0.3930863744727673
        },
        28: {
            'x': 0.9774375,
            'y': 0.5172382174949569
        },
        29: {
            'x': 0.9066875,
            'y': 0.5682193288098295
        },
        30: {
            'x': 0.8296875,
            'y': 0.3638364203190904
        },
        31: {
            'x': 0.749,
            'y': 0.458
        },
        32: {
            'x': 0.7759375,
            'y': 0.5634513112048414
        },
        33: {
            'x': 0.7009375,
            'y': 0.62800293416468
        },
        34: {
            'x': 0.6451875,
            'y': 0.5682193288098295
        },
        35: {
            'x': 0.6044375,
            'y': 0.6269026224096828
        },
        36: {
            'x': 0.6420625,
            'y': 0.44553456812763614
        },
        37: {
            'x': 0.4620625,
            'y': 0.6600953603520998
        },
        38: {
            'x': 0.4358125,
            'y': 0.6954887218045113
        },
        39: {
            'x': 0.3676875,
            'y': 0.4493856592701265
        },
        40: {
            'x': 0.233625,
            'y': 0.3753896937465615
        },
        41: {
            'x': 0.2530625,
            'y': 0.47414267375756464
        },
        42: {
            'x': 0.1705625,
            'y': 0.40702365670273244
        },
        43: {
            'x': 0.110625,
            'y': 0.3252338162479369
        },
        44: {
            'x': 0.1148125,
            'y': 0.4878965706950303
        },
        45: {
            'x': 0.0616875,
            'y': 0.44553456812763614
        },
        46: {
            'x': 0.0843125,
            'y': 0.606821932880983
        },
        47: {
            'x': 0.0218125,
            'y': 0.7426187419768935
        },
        48: {
            'x': 0.0401875,
            'y': 0.8073537502292316
        },
        49: {
            'x': 0.1705625,
            'y': 0.6739409499358151
        },
        50: {
            'x': 0.12375,
            'y': 0.800385109114249
        },
        51: {
            'x': 0.0218125,
            'y': 0.9392994681826518
        },
        52: {
            'x': 0.105875,
            'y': 0.9392994681826518
        },
        53: {
            'x': 0.24675,
            'y': 0.8459563543003851
        },
        54: {
            'x': 0.2795,
            'y': 0.7858059783605355
        },
        55: {
            'x': 0.1905,
            'y': 0.7611406565193471
        },
        56: {
            'x': 0.2701875,
            'y': 0.5971942050247571
        },
        57: {
            'x': 0.31975,
            'y': 0.6846689895470384
        },
        58: {
            'x': 0.4433125,
            'y': 0.8653035026590867
        },
        59: {
            'x': 0.3571875,
            'y': 0.9470933431138823
        },
        60: {
            'x': 0.266375,
            'y': 0.9492939666238768
        },
        61: {
            'x': 0.6313125,
            'y': 0.9674491105813314
        },
        62: {
            'x': 0.5458125,
            'y': 0.9586466165413534
        },
        63: {
            'x': 0.5181875,
            'y': 0.7501375389693746
        },
        64: {
            'x': 0.572125,
            'y': 0.7040161379057399
        },
        65: {
            'x': 0.7613125,
            'y': 0.7587566477168531
        },
        66: {
            'x': 0.6731875,
            'y': 0.7519713918943701
        },
        67: {
            'x': 0.8165625,
            'y': 0.6762332660920595
        },
        68: {
            'x': 0.956,
            'y': 0.660
        },
        69: {
            'x': 0.823,
            'y': 0.807
        },
        70: {
            'x': 0.940875,
            'y': 0.7811296534017972
        },
        71: {
            'x': 0.981,
            'y': 0.828
        },
        72: {
            'x': 0.97425,
            'y': 0.9586466165413534
        },
        73: {
            'x': 0.856,
            'y': 0.9663487988263342
        },
        74: {
            'x': 0.792375,
            'y': 0.8653035026590867
        }
    },
    'canopy': {
        1: {"x": 0.07, "y": 0.07},
        2: {"x": 0.029609375, "y": 0.1115},
        3: {"x": 0.18, "y": 0.07},
        4: {"x": 0.15390625, "y": 0.1243125},
        5: {"x": 0.24359375, "y": 0.1499375},
        6: {"x": 0.13265625, "y": 0.180125},
        7: {"x": 0.121875, "y": 0.282625},
        8: {"x": 0.04, "y": 0.27},
        9: {"x": 0.33, "y": 0.15},
        10: {"x": 0.1859375, "y": 0.2539375},
        11: {"x": 0.28203125, "y": 0.2898125},
        12: {"x": 0.4165625, "y": 0.277},
        13: {"x": 0.498125, "y": 0.26675},
        14: {"x": 0.274375, "y": 0.3635625},
        15: {"x": 0.266015625, "y": 0.4516875},
        16: {"x": 0.19234375, "y": 0.385125},
        17: {"x": 0.169921875, "y": 0.4645},
        18: {"x": 0.048828125, "y": 0.4106875},
        19: {"x": 0.0584375, "y": 0.459875},
        20: {"x": 0.020625, "y": 0.5556875},
        21: {"x": 0.09, "y": 0.53},
        22: {"x": 0.074453125, "y": 0.6115625},
        23: {"x": 0.02640625, "y": 0.7006875},
        24: {"x": 0.121875, "y": 0.6525625},
        25: {"x": 0.1603125, "y": 0.593125},
        26: {"x": 0.25, "y": 0.665375},
        27: {"x": 0.3121875, "y": 0.6269375},
        28: {"x": 0.21, "y": 0.64},
        29: {"x": 0.298046875, "y": 0.593125},
        30: {"x": 0.22953125, "y": 0.530125},
        31: {"x": 0.328203125, "y": 0.5173125},
        32: {"x": 0.4415625, "y": 0.4106875},
        33: {"x": 0.46609375, "y": 0.499625},
        34: {"x": 0.37625, "y": 0.6059375},
        35: {"x": 0.34421875, "y": 0.66075},
        36: {"x": 0.334609375, "y": 0.7155625},
        37: {"x": 0.38625, "y": 0.7621875},
        38: {"x": 0.18046875, "y": 0.7135},
        39: {"x": 0.378125, "y": 0.5115625},
        40: {"x": 0.2371875, "y": 0.800625},
        41: {"x": 0.048828125, "y": 0.7878125},
        42: {"x": 0.137890625, "y": 0.8964375},
        43: {"x": 0.21796875, "y": 0.9538125},
        44: {"x": 0.360234375, "y": 0.861875},
        45: {"x": 0.35421875, "y": 0.966625},
        46: {"x": 0.46609375, "y": 0.941},
        47: {"x": 0.5421875, "y": 0.8964375},
        48: {"x": 0.583828125, "y": 0.83625},
        49: {"x": 0.62671875, "y": 0.9323125},
        50: {"x": 0.710078125, "y": 0.9066875},
        51: {"x": 0.7959375, "y": 0.9691875},
        52: {"x": 0.90421875, "y": 0.935375},
        53: {"x": 0.958671875, "y": 0.8875},
        54: {"x": 0.920234375, "y": 0.8298125},
        55: {"x": 0.716484375, "y": 0.8234375},
        56: {"x": 0.59984375, "y": 0.7826875},
        57: {"x": 0.417890625, "y": 0.6945625},
        58: {"x": 0.51015625, "y": 0.624375},
        59: {"x": 0.6428125, "y": 0.691},
        60: {"x": 0.62671875, "y": 0.5685},
        61: {"x": 0.44859375, "y": 0.6115625},
        62: {"x": 0.514140625, "y": 0.5380625},
        63: {"x": 0.5678125, "y": 0.4516875},
        64: {"x": 0.72, "y": 0.54},
        65: {"x": 0.736953125, "y": 0.593125},
        66: {"x": 0.6959375, "y": 0.665375},
        67: {"x": 0.768984375, "y": 0.665375},
        68: {"x": 0.851015625, "y": 0.6899375},
        69: {"x": 0.93, "y": 0.66},
        70: {"x": 0.97625, "y": 0.7309375},
        71: {"x": 0.93, "y": 0.59},
        72: {"x": 0.801015625, "y": 0.553125},
        73: {"x": 0.862578125, "y": 0.4568125},
        74: {"x": 0.826796875, "y": 0.5070625},
        75: {"x": 0.6853125, "y": 0.490125},
        76: {"x": 0.6459375, "y": 0.4260625},
        77: {"x": 0.7325, "y": 0.43425},
        78: {"x": 0.7971875, "y": 0.3789375},
        79: {"x": 0.8428125, "y": 0.35075},
        80: {"x": 0.97625, "y": 0.4260625},
        81: {"x": 0.97, "y": 0.38},
        82: {"x": 0.81703125, "y": 0.3218125},
        83: {"x": 0.58921875, "y": 0.087375},
        84: {"x": 0.5946875, "y": 0.3953125},
        85: {"x": 0.6371875, "y": 0.3314375},
        86: {"x": 0.56265625, "y": 0.282625},
        87: {"x": 0.51171875, "y": 0.1545},
        88: {"x": 0.60515625, "y": 0.1755625},
        89: {"x": 0.66203125, "y": 0.20575},
        90: {"x": 0.57875, "y": 0.019625},
        91: {"x": 0.70, "y": 0.055},
        92: {"x": 0.768984375, "y": 0.2231875},
        93: {"x": 0.81703125, "y": 0.26675},
        94: {"x": 0.801015625, "y": 0.1929375},
        95: {"x": 0.886875, "y": 0.20575},
        96: {"x": 0.97625, "y": 0.16275},
        97: {"x": 0.87484375, "y": 0.169875},
        98: {"x": 0.915703125, "y": 0.091},
        99: {"x": 0.49, "y": 0.103},
        100: {"x": 0.02, "y": 0.93},
        101: {"x": 0.96, "y": 0.32},
        102: {"x": 0.57, "y": 0.98}        
    },
    'collection': {
        1: {
            'x': 0.118,
            'y': 0.694
        },
        2: {
            'x': 0.802875,
            'y': 0.8193634394267622
        },
        3: {
            'x': 0.2255,
            'y': 0.9755040826528912
        },
        4: {
            'x': 0.56625,
            'y': 0.08398600233294451
        },
        5: {
            'x': 0.728375,
            'y': 0.6973837693717714
        },
        6: {
            'x': 0.383625,
            'y': 0.7441259790034994
        },
        7: {
            'x': 0.7165625,
            'y': 0.4330944842526246
        },
        8: {
            'x': 0.213625,
            'y': 0.7033827695384103
        },
        9: {
            'x': 0.647,
            'y': 0.748
        },
        10: {
            'x': 0.314375,
            'y': 0.03149475087485419
        },
        11: {
            'x': 0.476,
            'y': 0.8351941343109481
        },
        12: {
            'x': 0.745625,
            'y': 0.8971838026995501
        },
        13: {
            'x': 0.314375,
            'y': 0.928178636893851
        },
        14: {
            'x': 0.2864375,
            'y': 0.9211798033661056
        },
        15: {
            'x': 0.4315625,
            'y': 0.9287618730211631
        },
        16: {
            'x': 0.355875,
            'y': 0.944009331778037
        },
        17: {
            'x': 0.9513125,
            'y': 0.13206132311281454
        },
        18: {
            'x': 0.610875,
            'y': 0.26112314614230964
        },
        19: {
            'x': 0.20775,
            'y': 0.30844859190134977
        },
        20: {
            'x': 0.5515,
            'y': 0.25729045159140146
        },
        21: {
            'x': 0.662,
            'y': 0.6062322946175638
        },
        22: {
            'x': 0.1385625,
            'y': 0.5240793201133145
        },
        23: {
            'x': 0.443375,
            'y': 0.4999166805532411
        },
        24: {
            'x': 0.783875,
            'y': 0.05999000166638894
        },
        25: {
            'x': 0.108125,
            'y': 0.7675387435427429
        },
        26: {
            'x': 0.07775,
            'y': 0.4405932344609232
        },
        27: {
            'x': 0.48375,
            'y': 0.13206132311281454
        },
        28: {
            'x': 0.255125,
            'y': 0.5157473754374271
        },
        29: {
            'x': 0.97125,
            'y': 0.36977170471588067
        },
        30: {
            'x': 0.57,
            'y': 0.91
        },
        31: {
            'x': 0.783875,
            'y': 0.2926178970171638
        },
        32: {
            'x': 0.5349375,
            'y': 0.6815530744875854
        },
        33: {
            'x': 0.019,
            'y': 0.7517080486585569
        },
        34: {
            'x': 0.97975,
            'y': 0.627812031328112
        },
        35: {
            'x': 0.324375,
            'y': 0.36977170471588067
        },
        36: {
            'x': 0.9560625,
            'y': 0.9211798033661056
        },
        37: {
            'x': 0.0540625,
            'y': 0.1472254624229295
        },
        38: {
            'x': 0.79575,
            'y': 0.9755040826528912
        },
        39: {
            'x': 0.967875,
            'y': 0.25095817363772704
        },
        40: {
            'x': 0.348125,
            'y': 0.25095817363772704
        },
        41: {
            'x': 0.922875,
            'y': 0.7599566738876854
        },
        42: {
            'x': 0.072125,
            'y': 0.3584402599566739
        },
        43: {
            'x': 0.826625,
            'y': 0.643559406765539
        },
        44: {
            'x': 0.599,
            'y': 0.4330944842526246
        },
        45: {
            'x': 0.849875,
            'y': 0.2132977837027162
        },
        46: {
            'x': 0.68575,
            'y': 0.9755040826528912
        },
        47: {
            'x': 0.9076875,
            'y': 0.6717213797700383
        },
        48: {
            'x': 0.679125,
            'y': 0.7877853691051492
        },
        49: {
            'x': 0.4196875,
            'y': 0.5822362939510082
        },
        50: {
            'x': 0.025125,
            'y': 0.5506582236293951
        },
        51: {
            'x': 0.267,
            'y': 0.8585235794034327
        },
        52: {
            'x': 0.873625,
            'y': 0.266788868521913
        },
        53: {
            'x': 0.048875,
            'y': 0.25095817363772704
        },
        54: {
            'x': 0.68575,
            'y': 0.2132977837027162
        },
        55: {
            'x': 0.791,
            'y': 0.7033827695384103
        },
        56: {
            'x': 0.443375,
            'y': 0.38560239960006665
        }
    },
    'descendant': {
        1: {
            'x': 0.692,
            'y': 0.748
        },
        2: {
            'x': 0.8423849858827437,
            'y': 0.6819375
        },
        3: {
            'x': 0.812,
            'y': 0.884
        },
        4: {
            'x': 0.1807839229363893,
            'y': 0.8878125
        },
        5: {
            'x': 0.2528649725959143,
            'y': 0.77125
        },
        6: {
            'x': 0.37402424846371035,
            'y': 0.72325
        },
        7: {
            'x': 0.142,
            'y': 0.591
        },
        8: {
            'x': 0.287,
            'y': 0.681
        },
        9: {
            'x': 0.943,
            'y': 0.650
        },
        10: {
            'x': 0.9577312738747716,
            'y': 0.89375
        },
        11: {
            'x': 0.9487626640093008,
            'y': 0.812
        },
        12: {
            'x': 0.8636439129712672,
            'y': 0.8878125
        },
        13: {
            'x': 0.6642584288324198,
            'y': 0.9483125
        },
        14: {
            'x': 0.6030559707689752,
            'y': 0.885875
        },
        15: {
            'x': 0.4059126390964956,
            'y': 0.89375
        },
        16: {
            'x': 0.3,
            'y': 0.95
        },
        17: {
            'x': 0.04617173227038698,
            'y': 0.737625
        },
        18: {
            'x': 0.5,
            'y': 0.92
        },
        19: {
            'x': 0.07756186679953496,
            'y': 0.69925
        },
        20: {
            'x': 0.5794718485301444,
            'y': 0.636875
        },
        21: {
            'x': 0.853,
            'y': 0.588
        },
        22: {
            'x': 0.6725626972263744,
            'y': 0.624875
        },
        23: {
            'x': 0.9736754691911642,
            'y': 0.75925
        },
        24: {
            'x': 0.8583291811991364,
            'y': 0.797125
        },
        25: {
            'x': 0.9022587610031556,
            'y': 0.61
        },
        26: {
            'x': 0.8071748878923767,
            'y': 0.5513125
        },
        27: {
            'x': 0.07548579970104634,
            'y': 0.5125
        },
        28: {
            'x': 0.11825278192991197,
            'y': 0.54425
        },
        29: {
            'x': 0.2257100149476831,
            'y': 0.5005
        },
        30: {
            'x': 0.3081714000996512,
            'y': 0.5926875
        },
        31: {
            'x': 0.4952665670154459,
            'y': 0.57525
        },
        32: {
            'x': 0.15014117256269724,
            'y': 0.4084375
        },
        33: {
            'x': 0.2502906493937884,
            'y': 0.462625
        },
        34: {
            'x': 0.6084537452250457,
            'y': 0.5245
        },
        35: {
            'x': 0.5271549576482312,
            'y': 0.49
        },
        36: {
            'x': 0.8583291811991364,
            'y': 0.2328125
        },
        37: {
            'x': 0.8423849858827437,
            'y': 0.498625
        },
        38: {
            'x': 0.598,
            'y': 0.352
        },
        39: {
            'x': 0.5954160438465371,
            'y': 0.4703125
        },
        40: {
            'x': 0.2554392957980402,
            'y': 0.152125
        },
        41: {
            'x': 0.8636439129712672,
            'y': 0.4645625
        },
        42: {
            'x': 0.9182029563195483,
            'y': 0.4113125
        },
        43: {
            'x': 0.701461551237336,
            'y': 0.356125
        },
        44: {
            'x': 0.30069755854509217,
            'y': 0.4136875
        },
        45: {
            'x': 0.2257100149476831,
            'y': 0.2471875
        },
        46: {
            'x': 0.491,
            'y': 0.312
        },
        47: {
            'x': 0.27769473509383824,
            'y': 0.320125
        },
        48: {
            'x': 0.4378840724132204,
            'y': 0.450625
        },
        49: {
            'x': 0.4059126390964956,
            'y': 0.349875
        },
        50: {
            'x': 0.6508885567181532,
            'y': 0.380125
        },
        51: {
            'x': 0.16724796545424347,
            'y': 0.356125
        },
        52: {
            'x': 0.6311243979405414,
            'y': 0.3066875
        },
        53: {
            'x': 0.526,
            'y': 0.268
        },
        54: {
            'x': 0.42185683441288824,
            'y': 0.2414375
        },
        55: {
            'x': 0.5794718485301444,
            'y': 0.0705625
        },
        56: {
            'x': 0.071,
            'y': 0.342
        },
        57: {
            'x': 0.10945025743232022,
            'y': 0.4463125
        },
        58: {
            'x': 0.7679787410729115,
            'y': 0.2174375
        },
        59: {
            'x': 0.701461551237336,
            'y': 0.11475
        },
        60: {
            'x': 0.4710181033050988,
            'y': 0.105125
        },
        61: {
            'x': 0.7839229363893041,
            'y': 0.073
        }
    },
    'edibles': {
        1: {
            'x': 0.041,
            'y': 0.414
        },
        2: {
            'x': 0.0971875,
            'y': 0.2791298549758293
        },
        3: {
            'x': 0.181,
            'y': 0.185
        },
        4: {
            'x': 0.370375,
            'y': 0.07192865477579596
        },
        5: {
            'x': 0.3306875,
            'y': 0.2888814802467078
        },
        6: {
            'x': 0.3991875,
            'y': 0.31138523087181197
        },
        7: {
            'x': 0.4415625,
            'y': 0.14360726787797967
        },
        8: {
            'x': 0.4536875,
            'y': 0.24304050675112518
        },
        9: {
            'x': 0.439,
            'y': 0.289
        },
        10: {
            'x': 0.5156875,
            'y': 0.29529921653608937
        },
        11: {
            'x': 0.5808125,
            'y': 0.24304050675112518
        },
        12: {
            'x': 0.5278125,
            'y': 0.09518253042173695
        },
        13: {
            'x': 0.62925,
            'y': 0.09851641940323387
        },
        14: {
            'x': 0.6503125,
            'y': 0.039673278879813305
        },
        15: {
            'x': 0.7079375,
            'y': 0.17002833805634274
        },
        16: {
            'x': 0.842125,
            'y': 0.16619436572762128
        },
        17: {
            'x': 0.9409375,
            'y': 0.049424904150691784
        },
        18: {
            'x': 0.7321875,
            'y': 0.2317886314385731
        },
        19: {
            'x': 0.888625,
            'y': 0.2107017836306051
        },
        20: {
            'x': 0.934125,
            'y': 0.2888814802467078
        },
        21: {
            'x': 0.83,
            'y': 0.327554592432072
        },
        22: {
            'x': 0.2144375,
            'y': 0.776712785464244
        },
        23: {
            'x': 0.9786875,
            'y': 0.43240540090015
        },
        24: {
            'x': 0.9786875,
            'y': 0.5140856809468245
        },
        25: {
            'x': 0.8663125,
            'y': 0.5418403067177863
        },
        26: {
            'x': 0.6590625,
            'y': 0.48824804134022337
        },
        27: {
            'x': 0.950125,
            'y': 0.6496082680446741
        },
        28: {
            'x': 0.9786875,
            'y': 0.7864644107351225
        },
        29: {
            'x': 0.922,
            'y': 0.8548091348558093
        },
        30: {
            'x': 0.8329375,
            'y': 0.6986164360726788
        },
        31: {
            'x': 0.8004375,
            'y': 0.6276046007667945
        },
        32: {
            'x': 0.714,
            'y': 0.6496082680446741
        },
        33: {
            'x': 0.748125,
            'y': 0.5579263210535089
        },
        34: {
            'x': 0.3616875,
            'y': 0.49791631938656444
        },
        35: {
            'x': 0.6381875,
            'y': 0.3856476079346558
        },
        36: {
            'x': 0.626125,
            'y': 0.44440740123353895
        },
        37: {
            'x': 0.695875,
            'y': 0.5366727787964661
        },
        38: {
            'x': 0.695875,
            'y': 0.5947657942990499
        },
        39: {
            'x': 0.5808125,
            'y': 0.5824304050675112
        },
        40: {
            'x': 0.671625,
            'y': 0.7082847141190198
        },
        41: {
            'x': 0.74425,
            'y': 0.8225537589598266
        },
        42: {
            'x': 0.784625,
            'y': 0.903233872312052
        },
        43: {
            'x': 0.6565625,
            'y': 0.830305050841807
        },
        44: {
            'x': 0.6110625,
            'y': 0.8961493582263711
        },
        45: {
            'x': 0.391125,
            'y': 0.8477246207701283
        },
        46: {
            'x': 0.4658125,
            'y': 0.7606267711285214
        },
        47: {
            'x': 0.4813125,
            'y': 0.5985997666277713
        },
        48: {
            'x': 0.462375,
            'y': 0.534089014835806
        },
        49: {
            'x': 0.564625,
            'y': 0.4720786797799633
        },
        50: {
            'x': 0.4536875,
            'y': 0.39598266377729624
        },
        51: {
            'x': 0.556625,
            'y': 0.31138523087181197
        },
        52: {
            'x': 0.417375,
            'y': 0.4282380396732789
        },
        53: {
            'x': 0.417375,
            'y': 0.4927487914652442
        },
        54: {
            'x': 0.332625,
            'y': 0.42440406734455743
        },
        55: {
            'x': 0.308375,
            'y': 0.3920653442240373
        },
        56: {
            'x': 0.2183125,
            'y': 0.3798133022170362
        },
        57: {
            'x': 0.2425,
            'y': 0.5256709451575262
        },
        58: {
            'x': 0.1335625,
            'y': 0.5140856809468245
        },
        59: {
            'x': 0.0483125,
            'y': 0.7147857976329388
        },
        60: {
            'x': 0.092375,
            'y': 0.7928821470245041
        },
        61: {
            'x': 0.0390625,
            'y': 0.8961493582263711
        },
        62: {
            'x': 0.194,
            'y': 0.673
        },
        63: {
            'x': 0.3616875,
            'y': 0.670278379729955
        },
        64: {
            'x': 0.417,
            'y': 0.599
        },
        65: {
            'x': 0.303,
            'y': 0.801
        },
        66: {
            'x': 0.14275,
            'y': 0.44857476246041006
        }
    },
    'emergent': {
        1: {
            'x': 0.299,
            'y': 0.041
        },
        2: {
            'x': 0.3885625,
            'y': 0.0629
        },
        3: {
            'x': 0.212,
            'y': 0.094
        },
        4: {
            'x': 0.3035,
            'y': 0.1217
        },
        5: {
            'x': 0.288,
            'y': 0.1701
        },
        6: {
            'x': 0.1705,
            'y': 0.1616
        },
        7: {
            'x': 0.29775,
            'y': 0.2244
        },
        8: {
            'x': 0.17575,
            'y': 0.2324
        },
        9: {
            'x': 0.1705,
            'y': 0.2912
        },
        10: {
            'x': 0.1705,
            'y': 0.36
        },
        11: {
            'x': 0.29,
            'y': 0.28
        },
        12: {
            'x': 0.3444375,
            'y': 0.3311
        },
        13: {
            'x': 0.23625,
            'y': 0.38815
        },
        14: {
            'x': 0.065375,
            'y': 0.2852
        },
        15: {
            'x': 0.12375,
            'y': 0.5115
        },
        16: {
            'x': 0.1328125,
            'y': 0.6959
        },
        17: {
            'x': 0.273625,
            'y': 0.9601
        },
        18: {
            'x': 0.64125,
            'y': 0.899
        },
        19: {
            'x': 0.450875,
            'y': 0.8823
        },
        20: {
            'x': 0.47675,
            'y': 0.7866
        },
        21: {
            'x': 0.6351875,
            'y': 0.81655
        },
        22: {
            'x': 0.6819375,
            'y': 0.72085
        },
        23: {
            'x': 0.5726875,
            'y': 0.6959
        },
        24: {
            'x': 0.47175,
            'y': 0.6411
        },
        25: {
            'x': 0.64125,
            'y': 0.6341
        },
        26: {
            'x': 0.7895,
            'y': 0.6371
        },
        27: {
            'x': 0.7334375,
            'y': 0.6934
        },
        28: {
            'x': 0.62,
            'y': 0.35
        },
        29: {
            'x': 0.919125,
            'y': 0.6311
        },
        30: {
            'x': 0.592,
            'y': 0.5758
        },
        31: {
            'x': 0.560875,
            'y': 0.5334
        },
        32: {
            'x': 0.47175,
            'y': 0.5459
        },
        33: {
            'x': 0.8675,
            'y': 0.2862
        },
        34: {
            'x': 0.397,
            'y': 0.4866
        },
        35: {
            'x': 0.479875,
            'y': 0.43525
        },
        36: {
            'x': 0.7740625,
            'y': 0.356
        },
        37: {
            'x': 0.662375,
            'y': 0.39835
        },
        38: {
            'x': 0.662375,
            'y': 0.5065
        },
        39: {
            'x': 0.487875,
            'y': 0.31015
        },
        40: {
            'x': 0.9240625,
            'y': 0.49905
        },
        41: {
            'x': 0.5,
            'y': 0.5
        },
        42: {
            'x': 0.9135,
            'y': 0.3919
        },
        43: {
            'x': 0.910375,
            'y': 0.33355
        },
        44: {
            'x': 0.96,
            'y': 0.21
        },
        45: {
            'x': 0.9415,
            'y': 0.1483
        },
        46: {
            'x': 0.862375,
            'y': 0.1098
        },
        47: {
            'x': 0.95525,
            'y': 0.0644
        },
        48: {
            'x': 0.821875,
            'y': 0.1945
        },
        49: {
            'x': 0.749625,
            'y': 0.11925
        },
        50: {
            'x': 0.7278125,
            'y': 0.0769
        },
        51: {
            'x': 0.666375,
            'y': 0.05445
        },
        52: {
            'x': 0.5615,
            'y': 0.042
        },
        53: {
            'x': 0.404125,
            'y': 0.11875
        },
        54: {
            'x': 0.5415,
            'y': 0.08335
        },
        55: {
            'x': 0.4181875,
            'y': 0.1576
        },
        56: {
            'x': 0.6106875,
            'y': 0.11425
        },
        57: {
            'x': 0.54525,
            'y': 0.2015
        },
        58: {
            'x': 0.707875,
            'y': 0.1596
        },
        59: {
            'x': 0.662375,
            'y': 0.1965
        },
        60: {
            'x': 0.6169375,
            'y': 0.2663
        },
        61: {
            'x': 0.429,
            'y': 0.219
        },
        62: {
            'x': 0.5296875,
            'y': 0.2538
        },
        63: {
            'x': 0.4873125,
            'y': 0.3894
        },
        64: {
            'x': 0.89,
            'y': 0.72
        }
    },
    'father-time': {
        1: {
            'x': 0.884,
            'y': 0.221
        },
        2: {
            'x': 0.22700046075871602,
            'y': 0.2515625
        },
        3: {
            'x': 0.6060512978037168,
            'y': 0.5705
        },
        4: {
            'x': 0.3544002457379819,
            'y': 0.34825
        },
        5: {
            'x': 0.43142374443249887,
            'y': 0.101
        },
        6: {
            'x': 0.17808324374136078,
            'y': 0.08325
        },
        7: {
            'x': 0.7953463369682077,
            'y': 0.968125
        },
        8: {
            'x': 0.959,
            'y': 0.869
        },
        9: {
            'x': 0.078,
            'y': 0.450
        },
        10: {
            'x': 0.5886960528336661,
            'y': 0.932625
        },
        11: {
            'x': 0.487,
            'y': 0.319
        },
        12: {
            'x': 0.9358009522346797,
            'y': 0.746125
        },
        13: {
            'x': 0.9726616495162034,
            'y': 0.31875
        },
        14: {
            'x': 0.0866226386115804,
            'y': 0.8543125
        },
        15: {
            'x': 0.2922746121947474,
            'y': 0.708125
        },
        16: {
            'x': 0.9455536783904163,
            'y': 0.9724375
        },
        17: {
            'x': 0.49493165412379053,
            'y': 0.8245625
        },
        18: {
            'x': 0.2755337121793887,
            'y': 0.77975
        },
        19: {
            'x': 0.022193211488250653,
            'y': 0.6756875
        },
        20: {
            'x': 0.9501612655506066,
            'y': 0.066625
        },
        21: {
            'x': 0.4081554292735371,
            'y': 0.924125
        },
        22: {
            'x': 0.44294271233297494,
            'y': 0.7475625
        },
        23: {
            'x': 0.039471663338964826,
            'y': 0.8088125
        },
        24: {
            'x': 0.9224389494701275,
            'y': 0.5281875
        },
        25: {
            'x': 0.6785440024573798,
            'y': 0.3374375
        },
        26: {
            'x': 0.37951159576101984,
            'y': 0.5281875
        }
    },
    'forest-floor': {
        "1": {"x": 0.02, "y": 0.1},
        "2": {"x": 0.0831875, "y": 0.227375},
        "3": {"x": 0.12, "y": 0.07},
        "4": {"x": 0.2540625, "y": 0.047625},
        "5": {"x": 0.1668125, "y": 0.189875},
        "6": {"x": 0.03, "y": 0.35},
        "7": {"x": 0.2276875, "y": 0.302625},
        "8": {"x": 0.2315625, "y": 0.227375},
        "9": {"x": 0.15, "y": 0.26},
        "10": {"x": 0.1479375, "y": 0.423125},
        "11": {"x": 0.2315625, "y": 0.423125},
        "12": {"x": 0.0358125, "y": 0.572125},
        "13": {"x": 0.1728125, "y": 0.564625},
        "14": {"x": 0.1020625, "y": 0.609625},
        "15": {"x": 0.2315625, "y": 0.609625},
        "16": {"x": 0.2964375, "y": 0.689375},
        "17": {"x": 0.0264375, "y": 0.761875},
        "18": {"x": 0.1574375, "y": 0.853625},
        "19": {"x": 0.0475625, "y": 0.872375},
        "20": {"x": 0.0170625, "y": 0.859625},
        "21": {"x": 0.1850625, "y": 0.962875},
        "22": {"x": 0.2221875, "y": 0.872375},
        "23": {"x": 0.0644375, "y": 0.962875},
        "24": {"x": 0.1020625, "y": 0.977625},
        "25": {"x": 0.2254375, "y": 0.962875},
        "26": {"x": 0.2869375, "y": 0.815875},
        "27": {"x": 0.3360625, "y": 0.909875},
        "28": {"x": 0.3763125, "y": 0.944125},
        "29": {"x": 0.5226875, "y": 0.867125},
        "30": {"x": 0.6174375, "y": 0.967875},
        "31": {"x": 0.5956875, "y": 0.834625},
        "32": {"x": 0.5286875, "y": 0.748125},
        "33": {"x": 0.58625, "y": 0.701125},
        "34": {"x": 0.4948125, "y": 0.804625},
        "35": {"x": 0.3648125, "y": 0.759375},
        "36": {"x": 0.4076875, "y": 0.724125},
        "37": {"x": 0.3173125, "y": 0.797125},
        "38": {"x": 0.3454375, "y": 0.583375},
        "39": {"x": 0.4264375, "y": 0.620875},
        "40": {"x": 0.4978125, "y": 0.538875},
        "41": {"x": 0.64, "y": 0.63},
        "42": {"x": 0.5896875, "y": 0.545625},
        "43": {"x": 0.5801875, "y": 0.414125},
        "44": {"x": 0.5308125, "y": 0.348125},
        "45": {"x": 0.4400625, "y": 0.498375},
        "46": {"x": 0.446375, "y": 0.341875},
        "47": {"x": 0.3673125, "y": 0.376375},
        "48": {"x": 0.2984375, "y": 0.450875},
        "49": {"x": 0.31275, "y": 0.265125},
        "50": {"x": 0.3293125, "y": 0.079125},
        "51": {"x": 0.274, "y": 0.149},
        "52": {"x": 0.3649375, "y": 0.171625},
        "53": {"x": 0.4134375, "y": 0.139375},
        "54": {"x": 0.4805625, "y": 0.232875},
        "55": {"x": 0.5668125, "y": 0.171625},
        "56": {"x": 0.6975625, "y": 0.132625},
        "57": {"x": 0.7069375, "y": 0.278625},
        "58": {"x": 0.6866875, "y": 0.366875},
        "59": {"x": 0.7348125, "y": 0.547875},
        "60": {"x": 0.78, "y": 0.34},
        "61": {"x": 0.743125, "y": 0.067875},
        "62": {"x": 0.8285625, "y": 0.560625},
        "63": {"x": 0.8345625, "y": 0.508875},
        "64": {"x": 0.7644375, "y": 0.873625},
        "65": {"x": 0.7636875, "y": 0.751125},
        "66": {"x": 0.7175625, "y": 0.712125},
        "67": {"x": 0.6574375, "y": 0.749625},
        "68": {"x": 0.7278125, "y": 0.814125},
        "69": {"x": 0.7338125, "y": 0.96925},
        "70": {"x": 0.80, "y": 0.94},
        "71": {"x": 0.86, "y": 0.93},
        "72": {"x": 0.8305625, "y": 0.834375},
        "73": {"x": 0.895, "y": 0.929},
        "74": {"x": 0.8436875, "y": 0.974375},
        "75": {"x": 0.8938125, "y": 0.96925},
        "76": {"x": 0.9179375, "y": 0.939875},
        "77": {"x": 0.980125, "y": 0.958875},
        "78": {"x": 0.9778125, "y": 0.851125},
        "79": {"x": 0.9671875, "y": 0.743625},
        "80": {"x": 0.8714375, "y": 0.700375},
        "81": {"x": 0.78, "y": 0.77},
        "82": {"x": 0.8906875, "y": 0.547875},
        "83": {"x": 0.9529375, "y": 0.576625},
        "84": {"x": 0.89, "y": 0.28},
        "85": {"x": 0.8639375, "y": 0.436625},
        "86": {"x": 0.9654375, "y": 0.293625},
        "87": {"x": 0.85, "y": 0.17},
        "88": {"x": 0.9170625, "y": 0.034625},
        "89": {"x": 0.9560625, "y": 0.090375},
        "90": {"x": 0.82, "y": 0.11},
        "91": {"x": 0.85, "y": 0.25}
    },
    'gone': {
        1: {
            'x': 0.118,
            'y': 0.161
        },
        2: {
            'x': 0.21197734466100282,
            'y': 0.0878125
        },
        3: {
            'x': 0.5667166416791605,
            'y': 0.0514375
        },
        4: {
            'x': 0.86031984007996,
            'y': 0.095125
        },
        5: {
            'x': 0.42112277194735964,
            'y': 0.1316875
        },
        6: {
            'x': 0.49525237381309345,
            'y': 0.161
        },
        7: {
            'x': 0.6211894052973513,
            'y': 0.137625
        },
        8: {
            'x': 0.723888055972014,
            'y': 0.13175
        },
        9: {
            'x': 0.8765617191404298,
            'y': 0.281875
        },
        10: {
            'x': 0.41337664501082794,
            'y': 0.20775
        },
        11: {
            'x': 0.369,
            'y': 0.364
        },
        12: {
            'x': 0.21780776278527403,
            'y': 0.223875
        },
        13: {
            'x': 0.12843578210894552,
            'y': 0.331625
        },
        14: {
            'x': 0.6874895885390638,
            'y': 0.407625
        },
        15: {
            'x': 0.6130268199233716,
            'y': 0.721125
        },
        16: {
            'x': 0.6095285690488089,
            'y': 0.3520625
        },
        17: {
            'x': 0.77328002665334,
            'y': 0.2696875
        },
        18: {
            'x': 0.9005497251374313,
            'y': 0.388125
        },
        19: {
            'x': 0.3626520073296685,
            'y': 0.456375
        },
        20: {
            'x': 0.286023654839247,
            'y': 0.388125
        },
        21: {
            'x': 0.095952023988006,
            'y': 0.5325
        },
        22: {
            'x': 0.1853240046643345,
            'y': 0.497375
        },
        23: {
            'x': 0.21064467766116943,
            'y': 0.6514375
        },
        24: {
            'x': 0.42895219057138095,
            'y': 0.56175
        },
        25: {
            'x': 0.6134432783608196,
            'y': 0.556875
        },
        26: {
            'x': 0.736215225720473,
            'y': 0.432
        },
        27: {
            'x': 0.9512743628185907,
            'y': 0.544625
        },
        28: {
            'x': 0.9577711144427786,
            'y': 0.457875
        },
        29: {
            'x': 0.9707646176911544,
            'y': 0.63
        },
        30: {
            'x': 0.9707646176911544,
            'y': 0.6806875
        },
        31: {
            'x': 0.9187906046976512,
            'y': 0.7265
        },
        32: {
            'x': 0.8843911377644511,
            'y': 0.769875
        },
        33: {
            'x': 0.7641179410294853,
            'y': 0.6758125
        },
        34: {
            'x': 0.671247709478594,
            'y': 0.605625
        },
        35: {
            'x': 0.5452273863068465,
            'y': 0.6085
        },
        36: {
            'x': 0.5718807263035149,
            'y': 0.702125
        },
        37: {
            'x': 0.4127103115109112,
            'y': 0.7001875
        },
        38: {
            'x': 0.5044144594369482,
            'y': 0.6119375
        },
        39: {
            'x': 0.357487922705314,
            'y': 0.6514375
        },
        40: {
            'x': 0.466600033316675,
            'y': 0.713
        },
        41: {
            'x': 0.5777111444277861,
            'y': 0.7455
        },
        42: {
            'x': 0.27936031984008,
            'y': 0.742125
        },
        43: {
            'x': 0.6836581709145427,
            'y': 0.7879375
        },
        44: {
            'x': 0.2773613193403298,
            'y': 0.8176875
        },
        45: {
            'x': 0.6292686989838414,
            'y': 0.81525
        },
        46: {
            'x': 0.7108945527236382,
            'y': 0.721125
        },
        47: {
            'x': 0.7199733466600033,
            'y': 0.497375
        },
        48: {
            'x': 0.7570381475928702,
            'y': 0.7786875
        },
        49: {
            'x': 0.9382808595702149,
            'y': 0.8435
        },
        50: {
            'x': 0.9057971014492754,
            'y': 0.8961875
        },
        51: {
            'x': 0.671247709478594,
            'y': 0.926375
        },
        52: {
            'x': 0.5881226053639846,
            'y': 0.87525
        },
        53: {
            'x': 0.49391970681326003,
            'y': 0.8420625
        },
        54: {
            'x': 0.48575712143928035,
            'y': 0.928875
        },
        55: {
            'x': 0.5,
            'y': 0.5
        },
        56: {
            'x': 0.38997168082625355,
            'y': 0.922
        },
        57: {
            'x': 0.30684657671164417,
            'y': 0.848375
        },
        58: {
            'x': 0.28085957021489255,
            'y': 0.9341875
        },
        59: {
            'x': 0.12360486423454939,
            'y': 0.9045
        },
        60: {
            'x': 0.05413959686823255,
            'y': 0.8664375
        },
        61: {
            'x': 0.0755455605530568,
            'y': 0.7543125
        },
        62: {
            'x': 0.437447942695319,
            'y': 0.604125
        },
        63: {
            'x': 0.32608695652173914,
            'y': 0.71775
        }
    },
    'nantahala': {
        1: {
            'x': 0.470,
            'y': 0.488
        },
        2: {
            'x': 0.387,
            'y': 0.694
        },
        3: {
            'x': 0.3910625,
            'y': 0.5658048373644704
        },
        4: {
            'x': 0.6115,
            'y': 0.6924103419516263
        },
        5: {
            'x': 0.516625,
            'y': 0.45604670558799
        },
        6: {
            'x': 0.3314375,
            'y': 0.3921601334445371
        },
        7: {
            'x': 0.6074375,
            'y': 0.513511259382819
        },
        8: {
            'x': 0.2984375,
            'y': 0.7140950792326939
        },
        9: {
            'x': 0.698,
            'y': 0.659
        },
        10: {
            'x': 0.7384375,
            'y': 0.513511259382819
        },
        11: {
            'x': 0.505375,
            'y': 0.33527939949958296
        },
        12: {
            'x': 0.6051875,
            'y': 0.6043369474562135
        },
        13: {
            'x': 0.354,
            'y': 0.5012510425354462
        },
        14: {
            'x': 0.354,
            'y': 0.6085904920767307
        },
        15: {
            'x': 0.270875,
            'y': 0.47114261884904085
        },
        16: {
            'x': 0.6429375,
            'y': 0.3740617180984153
        }
    },
    'phasmid-eggs-1': {
        1: {
            'x': 0.065,
            'y': 0.160  
        },
        2: {
            'x': 0.144,
            'y': 0.168
        },
        3: {
            'x': 0.239,
            'y': 0.152  
        },
        4: {
            'x': 0.377,
            'y': 0.133
        },
        5: {
            'x': 0.45566969183205347,
            'y': 0.179
        },
        6: {
            'x': 0.560,
            'y': 0.128  
        },
        7: {
            'x': 0.6611149895282736,
            'y': 0.1185625
        },
        8: {
            'x': 0.7754064027126758,
            'y': 0.1238125
        },
        9: {
            'x': 0.867,
            'y': 0.070    
        },
        10: {
            'x': 0.071,
            'y': 0.3365625
        },
        11: {
            'x': 0.165,
            'y': 0.273625
        },
        12: {
            'x': 0.291,
            'y': 0.225
        },
        13: {
            'x': 0.347,
            'y': 0.39575
        },
        14: {
            'x': 0.44360227386057643,
            'y': 0.28325
        },
        15: {
            'x': 0.5358033310062831,
            'y': 0.3836875
        },
        16: {
            'x': 0.5849207140720056,
            'y': 0.237375
        },
        17: {
            'x': 0.7198563877530667,
            'y': 0.3135
        },
        18: {
            'x': 0.8393337987433929,
            'y': 0.237375
        },
        19: {
            'x': 0.066,
            'y': 0.531
        },
        20: {
            'x': 0.147,
            'y': 0.569
        },
        21: {
            'x': 0.260,
            'y': 0.429    
        },
        22: {
            'x': 0.3345277750074798,
            'y': 0.56475
        },
        23: {
            'x': 0.43562381569761643,
            'y': 0.5285
        },
        24: {
            'x': 0.5935972873242246,
            'y': 0.5
        },
        25: {
            'x': 0.6851500947441906,
            'y': 0.410875
        },
        26: {
            'x': 0.7754064027126758,
            'y': 0.46975
        },
        27: {
            'x': 0.872,
            'y': 0.428
        },
        28: {
            'x': 0.064,
            'y': 0.702    
        },
        29: {
            'x': 0.20831853994215618,
            'y': 0.699375
        },
        30: {
            'x': 0.347,
            'y': 0.638
        },
        31: {
            'x': 0.439,
            'y': 0.701    
        },
        32: {
            'x': 0.5608856088560885,
            'y': 0.68425
        },
        33: {
            'x': 0.6851500947441906,
            'y': 0.748375
        },
        34: {
            'x': 0.8514012167148699,
            'y': 0.741125
        },
        35: {
            'x': 0.054,
            'y': 0.973125
        },
        36: {
            'x': 0.15296798643662113,
            'y': 0.918125
        },
        37: {
            'x': 0.25409494365213922,
            'y': 0.942875
        },
        38: {
            'x': 0.36579335793357934,
            'y': 0.897625
        },
        39: {
            'x': 0.45965892091353344,
            'y': 0.951375
        },
        40: {
            'x': 0.5598883015857186,
            'y': 0.903
        },
        41: {
            'x': 0.6542335693627207,
            'y': 0.942875
        },
        42: {
            'x': 0.7680263289119378,
            'y': 0.9126875
        },
        43: {
            'x': 0.8618190884611549,
            'y': 0.942875
        }
    },
    'phasmid-eggs-2': {
        1: {
            'x': 0.067,
            'y': 0.131
        },
        2: {
            'x': 0.203,
            'y': 0.131
        },
        3: {
            'x': 0.33933933933933935,
            'y': 0.151
        },
        4: {
            'x': 0.45585585585585586,
            'y': 0.151
        },
        5: {
            'x': 0.575,
            'y': 0.131
        },
        6: {
            'x': 0.696996996996997,
            'y': 0.151
        },
        7: {
            'x': 0.8189189189189189,
            'y': 0.151
        },
        8: {
            'x': 0.043043043043043044,
            'y': 0.32125
        },
        9: {
            'x': 0.160,
            'y': 0.313
        },
        10: {
            'x': 0.2987987987987988,
            'y': 0.32125
        },
        11: {
            'x': 0.4465465465465465,
            'y': 0.32125
        },
        12: {
            'x': 0.5741741741741742,
            'y': 0.32125
        },
        13: {
            'x': 0.7214214214214214,
            'y': 0.32125
        },
        14: {
            'x': 0.8636636636636636,
            'y': 0.32125
        },
        15: {
            'x': 0.04054054054054054,
            'y': 0.525375
        },
        16: {
            'x': 0.13433433433433434,
            'y': 0.525375
        },
        17: {
            'x': 0.23933933933933935,
            'y': 0.525375
        },
        18: {
            'x': 0.3416416416416416,
            'y': 0.525375
        },
        19: {
            'x': 0.44334334334334335,
            'y': 0.525375
        },
        20: {
            'x': 0.5545545545545546,
            'y': 0.525375
        },
        21: {
            'x': 0.659,
            'y': 0.530
        },
        22: {
            'x': 0.7585585585585586,
            'y': 0.525375
        },
        23: {
            'x': 0.8766766766766767,
            'y': 0.525375
        },
        24: {
            'x': 0.04914914914914915,
            'y': 0.735
        },
        25: {
            'x': 0.16316316316316315,
            'y': 0.735
        },
        26: {
            'x': 0.3065065065065065,
            'y': 0.735
        },
        27: {
            'x': 0.44994994994994997,
            'y': 0.735
        },
        28: {
            'x': 0.5861861861861862,
            'y': 0.735
        },
        29: {
            'x': 0.7257757757757758,
            'y': 0.735
        },
        30: {
            'x': 0.8578578578578578,
            'y': 0.735
        },
        31: {
            'x': 0.06726726726726727,
            'y': 0.939
        },
        32: {
            'x': 0.2017017017017017,
            'y': 0.939
        },
        33: {
            'x': 0.32642642642642644,
            'y': 0.939
        },
        34: {
            'x': 0.45545545545545546,
            'y': 0.939
        },
        35: {
            'x': 0.5787787787787788,
            'y': 0.939
        },
        36: {
            'x': 0.7137137137137137,
            'y': 0.939
        },
        37: {
            'x': 0.836036036036036,
            'y': 0.939
        }
    },
    'trade': {
        1: {
            'x': 0.688,
            'y': 0.182
        },
        2: {
            'x': 0.07,
            'y': 0.88
        },
        3: {
            'x': 0.496,
            'y': 0.899
        },
        4: {
            'x': 0.445,
            'y': 0.421
        },
        5: {
            'x': 0.4429375,
            'y': 0.7665831244778614
        },
        6: {
            'x': 0.9685625,
            'y': 0.926984126984127
        },
        7: {
            'x': 0.9685625,
            'y': 0.5743525480367586
        },
        8: {
            'x': 0.27375,
            'y': 0.6082706766917293
        },
        9: {
            'x': 0.705,
            'y': 0.604
        },
        10: {
            'x': 0.545375,
            'y': 0.6324979114452799
        },
        11: {
            'x': 0.4429375,
            'y': 0.03441938178780284
        },
        12: {
            'x': 0.4770625,
            'y': 0.6774436090225564
        },
        13: {
            'x': 0.7533125,
            'y': 0.23425229741019216
        },
        14: {
            'x': 0.232875,
            'y': 0.5245614035087719
        },
        15: {
            'x': 0.798875,
            'y': 0.13391812865497077
        },
        16: {
            'x': 0.6959375,
            'y': 0.7120300751879699
        },
        17: {
            'x': 0.5890625,
            'y': 0.29365079365079366
        },
        18: {
            'x': 0.2866875,
            'y': 0.04753550543024227
        },
        19: {
            'x': 0.0973125,
            'y': 0.8149540517961571
        },
        20: {
            'x': 0.3735,
            'y': 0.5453634085213033
        },
        21: {
            'x': 0.1594375,
            'y': 0.790142021720969
        },
        22: {
            'x': 0.8713125,
            'y': 0.8246449456975773
        },
        23: {
            'x': 0.9458125,
            'y': 0.7949039264828739
        },
        24: {
            'x': 0.48725,
            'y': 0.36006683375104426
        },
        25: {
            'x': 0.773,
            'y': 0.6152046783625731
        },
        26: {
            'x': 0.2515,
            'y': 0.9096908939014202
        },
        27: {
            'x': 0.7404375,
            'y': 0.09941520467836257
        },
        28: {
            'x': 0.33325,
            'y': 0.0821219715956558
        },
        29: {
            'x': 0.88425,
            'y': 0.6428571428571429
        },
        30: {
            'x': 0.5583125,
            'y': 0.5165413533834586
        },
        31: {
            'x': 0.954,
            'y': 0.053
        },
        32: {
            'x': 0.278,
            'y': 0.277
        },
        33: {
            'x': 0.55,
            'y': 0.92
        },
        34: {
            'x': 0.962375,
            'y': 0.29365079365079366
        },
        35: {
            'x': 0.708875,
            'y': 0.5107769423558898
        },
        36: {
            'x': 0.46775,
            'y': 0.9615705931495405
        },
        37: {
            'x': 0.582625,
            'y': 0.6912280701754386
        },
        38: {
            'x': 0.858375,
            'y': 0.4776106934001671
        },
        39: {
            'x': 0.13975,
            'y': 0.9615705931495405
        },
        40: {
            'x': 0.8164375,
            'y': 0.04202172096908939
        },
        41: {
            'x': 0.3384375,
            'y': 0.7838763575605681
        },
        42: {
            'x': 0.2,
            'y': 0.44
        },
        43: {
            'x': 0.5324375,
            'y': 0.04202172096908939
        },
        44: {
            'x': 0.1004375,
            'y': 0.4776106934001671
        },
        45: {
            'x': 0.9535625,
            'y': 0.15129490392648287
        },
        46: {
            'x': 0.0973125,
            'y': 0.059314954051796154
        },
        47: {
            'x': 0.59,
            'y': 0.79
        },
        48: {
            'x': 0.359125,
            'y': 0.2078529657477026
        },
        49: {
            'x': 0.8035625,
            'y': 0.31720969089390144
        },
        50: {
            'x': 0.0491875,
            'y': 0.24185463659147868
        },
        51: {
            'x': 0.3661875,
            'y': 0.9197159565580618
        },
        52: {
            'x': 0.13975,
            'y': 0.42105263157894735
        },
        53: {
            'x': 0.7404375,
            'y': 0.926984126984127
        },
        54: {
            'x': 0.03625,
            'y': 0.9145363408521303
        },
        55: {
            'x': 0.9753125,
            'y': 0.6428571428571429
        },
        56: {
            'x': 0.6886875,
            'y': 0.33316624895572267
        },
        57: {
            'x': 0.4448125,
            'y': 0.3047619047619048
        },
        58: {
            'x': 0.6149375,
            'y': 0.6200501253132832
        },
        59: {
            'x': 0.1594375,
            'y': 0.6324979114452799
        },
        60: {
            'x': 0.6149375,
            'y': 0.22514619883040934
        },
        61: {
            'x': 0.4770625,
            'y': 0.5515455304928989
        },
        62: {
            'x': 0.028,
            'y': 0.6545530492898914
        },
        63: {
            'x': 0.8164375,
            'y': 0.790142021720969
        },
        64: {
            'x': 0.1299375,
            'y': 0.7015873015873015
        },
        65: {
            'x': 0.27,
            'y': 0.536
        },
        66: {
            'x': 0.374125,
            'y': 0.6670843776106934
        },
        67: {
            'x': 0.3771875,
            'y': 0.8323308270676691
        },
        68: {
            'x': 0.862,
            'y': 0.9615705931495405
        }
    },
    'understory': {
        1: {
            'x': 0.110,
            'y': 0.165
        },
        2: {
            'x': 0.4303125,
            'y': 0.046875
        },
        3: {
            'x': 0.209609375,
            'y': 0.193875
        },
        4: {
            'x': 0.12921875,
            'y': 0.2601875
        },
        5: {
            'x': 0.23703125,
            'y': 0.3520625
        },
        6: {
            'x': 0.948984375,
            'y': 0.882875
        },
        7: {
            'x': 0.046640625,
            'y': 0.6786875
        },
        8: {
            'x': 0.165546875,
            'y': 0.514375
        },
        9: {
            'x': 0.030703125,
            'y': 0.80375
        },
        10: {
            'x': 0.964921875,
            'y': 0.4020625
        },
        11: {
            'x': 0.256171875,
            'y': 0.4791875
        },
        12: {
            'x': 0.402265625,
            'y': 0.5235625
        },
        13: {
            'x': 0.389453125,
            'y': 0.6786875
        },
        14: {
            'x': 0.32484375,
            'y': 0.6158125
        },
        15: {
            'x': 0.1075,
            'y': 0.77825
        },
        16: {
            'x': 0.205078125,
            'y': 0.8323125
        },
        17: {
            'x': 0.1075,
            'y': 0.9318125
        },
        18: {
            'x': 0.335234375,
            'y': 0.9660625
        },
        19: {
            'x': 0.563046875,
            'y': 0.8420625
        },
        20: {
            'x': 0.378671875,
            'y': 0.8323125
        },
        21: {
            'x': 0.527265625,
            'y': 0.7399375
        },
        22: {
            'x': 0.474921875,
            'y': 0.70425
        },
        23: {
            'x': 0.589140625,
            'y': 0.6705625
        },
        24: {
            'x': 0.7696875,
            'y': 0.6833125
        },
        25: {
            'x': 0.414375,
            'y': 0.477125
        },
        26: {
            'x': 0.569375,
            'y': 0.4020625
        },
        27: {
            'x': 0.34671875,
            'y': 0.3893125
        },
        28: {
            'x': 0.4303125,
            'y': 0.323
        },
        29: {
            'x': 0.696953125,
            'y': 0.3265625
        },
        30: {
            'x': 0.33078125,
            'y': 0.2655625
        },
        31: {
            'x': 0.25296875,
            'y': 0.0815625
        },
        32: {
            'x': 0.79265625,
            'y': 0.4705
        },
        33: {
            'x': 0.506875,
            'y': 0.0815625
        },
        34: {
            'x': 0.6765625,
            'y': 0.0775
        },
        35: {
            'x': 0.506875,
            'y': 0.2091875
        },
        36: {
            'x': 0.617265625,
            'y': 0.2826875
        },
        37: {
            'x': 0.670390625,
            'y': 0.3804375
        },
        38: {
            'x': 0.605078125,
            'y': 0.4745
        },
        39: {
            'x': 0.6453125,
            'y': 0.5561875
        },
        40: {
            'x': 0.83859375,
            'y': 0.5439375
        },
        41: {
            'x': 0.601328125,
            'y': 0.7376875
        },
        42: {
            'x': 0.732734375,
            'y': 0.8450625
        },
        43: {
            'x': 0.609296875,
            'y': 0.9533125
        },
        44: {
            'x': 0.720859375,
            'y': 0.957375
        },
        45: {
            'x': 0.85453125,
            'y': 0.89075
        },
        46: {
            'x': 0.968125,
            'y': 0.9746875
        },
        47: {
            'x': 0.880390625,
            'y': 0.7654375
        },
        48: {
            'x': 0.958515625,
            'y': 0.7526875
        },
        49: {
            'x': 0.912265625,
            'y': 0.6705625
        },
        50: {
            'x': 0.964921875,
            'y': 0.613375
        },
        51: {
            'x': 0.867265625,
            'y': 0.6085
        },
        52: {
            'x': 0.7696875,
            'y': 0.6196875
        },
        53: {
            'x': 0.859140625,
            'y': 0.421
        },
        54: {
            'x': 0.912265625,
            'y': 0.488875
        },
        55: {
            'x': 0.7528125,
            'y': 0.3954375
        },
        56: {
            'x': 0.76078125,
            'y': 0.3648125
        },
        57: {
            'x': 0.736796875,
            'y': 0.2571875
        },
        58: {
            'x': 0.920234375,
            'y': 0.168375
        }
    }
}