<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'PrivacyPolicyPage',
    async metaInfo() {
        const title = 'Privacy Policy – Isabella Kirkland';

        const contactPage = await import('@/views/ContactPage.vue');

        console.log('contactPage',contactPage);
        
        // Description should be picked up as the first set of text on the page.

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title);
    },
    components: {
        GlobalHeader,
        GlobalFooter
    }
}
</script>

<template>
  <div>
    <GlobalHeader/>
    <div class="content pad">
        <h2>Privacy Policy</h2>
        <p>Isabella Kirkland respects your privacy and is committed to protecting any personal information you may provide while using our website. This Privacy Policy outlines the types of information we may collect, how we use it, and the steps we take to ensure it remains secure.</p>
        <h4>Information We Collect:</h4>
        <ul>
            <li><strong>Personal Information:</strong> We may collect personal information such as your name, email address, and any other details you voluntarily provide.</li>
            <li><strong>Non-Personal Information:</strong> We may also gather non-personal information, such as browser type, IP address, and referring website.</li>
        </ul>
        <h4>How We Use Your Information:</h4>
        <p>We use the collected information for:</p>
        <ul>
            <li>Improving our website's functionality and user experience.</li>
            <li>Analyzing website traffic through Google Analytics.</li>
            <li>Responding to your inquiries or requests.</li>
        </ul>
        <h4>Security Measures:</h4>
        <p>We take appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.</p>
        <h4>Third-Party Services:</h4>
        <p>We use Google Analytics to analyze website traffic. Please refer to the Cookie Policy for more information on how Google Analytics uses cookies.</p>
        <h4>Changes to the Privacy Policy:</h4>
        <p>We may update this Privacy Policy periodically. The latest version will always be available on this page.</p>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
.content {
    gap: 0em;
}
</style>
