// For use with Search - Not ideal as text is duplicated across the codebase.
export default [
    {
        name: 'Contact',
        slug: 'contact',
        description: `Thank you
            for your interest.
            For inquiries about purchasing an art work or reproducing an image, contact info@hosfeltgallery.com.
            
            For all other inquiries, click here.
            Sausalito, California

            Taxa Prints
            This is a suite of signed, numbered prints of the six Taxa paintings. They are presented in a silk-covered clamshell box and include the 48-page Taxa catalog with an introduction by E. O.Wiison.

            Created by Isabella Kirkland between 1999 and 2005 published by Feature Inc., NY.
            Printed at Electric Works, San Francisco by Nicolas Widman, 2006
            Digital imaging and color correction by Maizie Gilbert
            Printed on Moab Entrada Natural 330 g/sm in an edition of 50
            Please email for more information
            info@hosfeltgallery.com
        `,
        image: '/img/isabella-kirkland-portrait.jpg',
        type: 'page',
        path: '/contact'
    },
    {
        name: 'Curriculum Vitae',
        slug: 'curriculum-vitae',
        description: `
        Born:
        1954 Old Lyme, CT
        
        Education:
        1971-1973 Guilford College, Greensboro, NC
        1975 Virginia Commonwealth University, Richmond, VA
        1978 San Francisco Art Institute, San Francisco, CA
        
        Solo Exhibitions
        2021
        ADAA The Art Show, Hosfelt Booth, New York, NY
        Isabella Kirkland: The Small Matter, Hosfelt Gallery, San Francisco, CA
        2015
        Isabella Kirkland: New Paintings, Bolinas Museum, Bolinas, CA
        2014
        Isabella Kirkland: Stilled Life, Dayton Art Institute, Dayton, OH
        Life Cycles: Isabella Kirkland’s TAXA, St. Louis Art Museum, St. Louis, MO
        2012
        Promise and Peril: A Study in Biodiversity, Hazel Wolfe Gallery, Brower Center, Berkeley, CA
        2011
        NOVA Feature Inc, New York, NY
        2008
        NOVA (New Species): Paintings by Isabella Kirkland, Toledo Art Museum, Toledo, OH
        Taxa: Paintings by Isabella Kirkland, Upstairs Gallery, National Academy of Sciences, Washington, DC
        2005
        Isabella Kirkland, Feature Inc., New York, NY
        2003
        Isabella Kirkland, Feature Inc., New York, NY
        1984
        Pandora’s Box: SFAI Annual, New Langton Arts, San Francisco, CA
        1983
        Micronic Obsessions, Real Art Ways, Hartford, CT
        1980
        Micronic Problems, 80 Langton Street Gallery, San Francisco, CA
        Untitled, Introductions, Gallery Paule Anglim, San Francisco, CA
        
        Group Exhibitions
        2023
        Off the Grid: Post Formal Conceptualism, Hosfelt Gallery, San Francisco, CA
        2021
        Where We Are, Hosfelt Gallery, San Francisco, CA
        2020
        Bird, Nest, Nature, Bedford Gallery, Lesher Center for the Arts, Walnut Creek, CA
        2019
        Between Them, Hosfelt Gallery, San Francisco, CA
        Endangered Species: Artists on the Front Line of Biodiversity, Whatcom Museum, Bellingham, WA
        Anthropocenic: Art About the Natural World in the Human Era, Bates Museum of Art, Olin Arts Center, Lewiston, ME
        2018
        Frankenstein’s Birthday Party, Hosfelt Gallery, San Francisco, CA
        Big Botany, Conversations with the Plant World, Spencer Museum of Art, University of Kansas, Lawrence, KS
        Making Life Visible, Faulconer Gallery, Grinnell College, Grinnell, IA
        Strange Attractors: The Anthology of Interplanetary Folk Art, Vol., Life on Earth, Redling Fine Art, Los Angeles, CA
        2017
        State of the Art: Discovering American Art Now, Frist Museum of Art, Nashville, TN
        So I traveled a great deal…, Matthew Marks Gallery, New York, NY
        2016
        Bird in Hand, Palo Alto Art Center, Palo Alto, CA
        State of the Art, Crystal Bridges Museum of Art, Bentonville, AR
        2014
        Promise and Peril: A Study in Biodiversity, Hazel Wolfe Gallery, Brower Center, Berkeley, CA
        2013
        Nature’s Toolbox: Biodiversity, Art and Invention, Field Museum, Chicago, IL
        Pop: Politics of Place, Art Design and Architecture Museum, University of California, Santa Barbara, CA
        The Animals Look Back at Us, Byrdcliffe Kleinert/James Center for the Arts, Woodstock, NY and Williamsburg Art & Historical Center, Brooklyn, NY
        Nature’s Toolbox: Biodiversity, Art, and Invention, The Leonardo, Salt Lake City, UT and Ulrich Museum of Art at Wichita State University, KS
        2012
        Punt, Feature Inc., New York, NY
        Tending toward the Untamed: Artists Respond to the Wild Garden, Glyndor Gallery, Wave Hill, Bronx, NY
        2011
        Global Flora: Botanical Imagery and Exploration, Davis Museum and Cultural Center, Wellesley College, Wellesley, MA
        2009
        NeoRococo, Shore Institute of Contemporary Art, Long Branch, NJ
        2008
        Trouble in Paradise: Examining Discord Between Nature and Society, Tucson Museum of Art
        2007
        Painting as Fact – Fact as Fiction, de Pury & Luxembourg, Zurich, Switzerland
        2006
        Biodiversity: Order, Consumption & Man, Sun Valley Center for the Arts, Sun Valley, ID
        The Way of All Flesh, Bravin Lee Productions, New York, NY
        2005
        ECO: Art About the Environment, Fine Arts Gallery, San Francisco State University, San Francisco, CA
        2004
        Continuities: Themes from the Fine Art Collection Reflected in Recent Northern California Art, Richard L. Nelson Gallery, University of California, Davis, CA
        Natural Histories: Realism Revisited, Scottsdale Museum of Contemporary Art, Scottsdale, AZ
        2002
        Isabella Kirkland and Michael St. John, Feature Inc., New York, NY
        2001
        Taxa: Isabella Kirkland, Yerba Buena Center for the Arts, San Francisco, CA
        2000
        Beauty on the Wing: The Double Lives of Butterflies, Harvard Museum of Natural History, Cambridge, MA
        6th Anniversary Group Exhibition, Heritage Fine Arts, San Jose, CA
        1999
        Natural World Observed, Bedford Gallery, Dean Lescher Center for the Arts, Walnut Creek, CA
        1995
        Animals and Artists, Joan Roebuck Gallery, Lafayette, CA
        1992
        Still Lives, Zen Center Hospice Guest House, San Francisco, CA
        1991
        Erotic Drawings, ArtSpace, San Francisco, CA
        LOCOmotion: 4 one-minute animations, ArtSpace, San Francisco, CA
        1990
        The Dirty Show, The Living Room, San Francisco, CA
        1989
        Nepotism, Hallwalls, Buffalo, NY
        1987
        Perdurabo, Bard College, New York, NY
        1986
        Night of Fog (installation), Cat Club, New York, NY
        1985
        Payola, MO David Gallery, New York, NY
        1983
        Sacred Artifacts, Objects of Devotion, Alternative Museum, New York, NY
        
        Press
        2023
        David M. Roth, The Grid Revisited, Squarecylinder, May
        https://www.squarecylinder.com/2023/05/the-grid-revisited/
        2022
        Heather Riverun, The Power of Attention: How Isabella Kirkland's Work Stands Witness to Nature's Losses and Gains, Kinute, September 22
        PODCAST: Interview with David Helvarg, Isabella Kirkland's Art of Nature, Rising Tide #64, Blue Frontier, August 29
        2021
        David M. Roth, The Nature Conundrum, Squarecylinder, June
        https://www.squarecylinder.com/2021/06/the-nature-conundrum/
        Renny Pritikin, On Isabella Kirkland @Hosfelt, Squarecylinder, May
        2018
        Charles Desmarias, Summer exhibition at Hosfelt Gallery a monstrous affair, San Francisco Chronicle, July 20
        2017
        Jennifer Krasinski, Paradise Lost, The Village Voice, p. 27, July 19
        Dan Piepenbring, "So I Traveled a Great Deal...", The Paris Review July 10
        https://www.theparisreview.org/blog/2017/07/10/so-i-traveled-a-great-deal/
        Holland Cotter, “So I traveled a great deal...”, The New York Times, p. C14, August 11
        https://mmg.nyc3.cdn.digitaloceanspaces.com/online/so-i-traveled-a-great-deal- organized-by-vincent-fecteau-and-jordan-stein-07-2017/2017-08-11_The-New-York- Times.pdf
        In These Hands, Orion 35th Anniversary Issue, Volume 36, #4
        2014
        Death and Taxa: Isabella Kirkland: Stilled Life. AEQAI, April 26
        2013
        De-Extinction, (interview) studio360.org, July 19
        2012
        Donna Maria deCreeft, Wave Hill: Artists Respond to the Wild Garden, Artezine, July
        Isabella Kirkland's forward to Tiffany Bozic: Drawn by Instinct, Gingko Press, 2012
        Zygote Quarterly, no.3, Fall, pp. 28-33 (reproductions)
        2011
        Ken Johnson, Isabella Kirkland: ‘Nova’, New York Times, C25, June 9
        2010
        Kyoto Journal no.75 (reproduction)
        2009
        Andrew Revkin, On the Origin (and Fate) of Species, New York Times February 13
        2008
        Anna Lena Phillips, Ars Scientifica, American Scientist, May - June, pp. 205-206
        Steve Pollick, 250 New Plants, Animals at Art Museum, Toledo Blade, September 7
        Samantha Campos, Life on Canvas, Pacific Sun, September 19-25
        2007
        Andrew Revkin, Permanent Art, Evanescent Life, New York Times, November 1 (interview)
        Andrew Revkin, Paintings of Nature's Comeback Kids, Dot Earth, New York Times, October 30
        2006
        Michael Ames, Going, Going, Almost Gone, Idaho Mountain Express, February 15, p. C1
        Karen Bossick, ‘Gone,’ ‘Back’ and ‘Nova’, Wood River Journal, February 15, p. B2
        2004
        Richard Nilsen, How Real Can It Get?, Arizona Republic, May 30, pp. E:1, 10 (reproduction)
        Dolores Tropiano, Natural World Show, Scottsdale Republic, September (reproduction)
        2002
        Bob Nickas, Best of 2002: Top Ten, Artforum, December, pp. 116-117
        2001
        David Bonetti, Three Artists Who Grapple with History, San Francisco Chronicle, November 20, p. D1
        2000
        Stewart Brand, Whole Earth Covers, CoEvolution Quarterly, Fall, pp. 58-59
        
        Publications, Portfolios, and Projects
        2023
        Speaker at TED Countdown Summit, July 2023
        2020
        Cover artwork for Animal Traffic: Lively Capital in the Global Exotic Pet Trade by Rosemary-Claire Collard, Duke University Press
        Isabella Kirkland, Umbigo Magazine Issue #72, https://umbigomagazine.com/en/blog/2020/04/22/umbigo-72-pt-download-gratuito/
        2019
        Taxa, Antennae, the Journal of Nature in Visual Culture, Issue #49
        2017
        In These Hands, Orion 35th Anniversary Issue, Volume 36, #4
        Cover Artwork for Extinction Studies: Stories of Time, Death, and Generations, by Rose, van Dooren, and Chrulew, Columbia University Press
        2016
        Speaker at The Long Now Foundation, The Interval, San Francisco, CA
        Illustrations in Imagining Extinction: The Cultural Meanings of Endangered Species, by Ursula K Heise, The University of Chicago Press
        Speaker at DREAMFORCE ‘16, San Francisco, CA
        2013
        Speaker at opening of Nature’s Toolbox, Ulrich Museum, Wichita, KS
        Panelist and speaker at The Dayton Art Institute, OH
        Speaker at Science Illustration Certificate Program, California State University, Monterey Bay, CA
        Speaker at TEDx DeExtinction, conference convened by Revive and Restore with the support of TED and in partnership with the National Geographic Society, Washington DC
        In discussion with Stewart Brand, David Brower Center, March 7; in conjunction with the Long Now Foundation
        2012
        Forward written by Isabella Kirkland to Tiffany Bozic: Drawn by Instinct, Ginko Press, Berkeley, CA
        2011
        NOVA, limited-edition suite of prints, published by Feature Inc., New York, NY
        Exit Art 2011 (and final) print portfolio (with Walton Ford, Charles Juhasz-Alvarado, Robert Kushner, Enoc Perez, and Alexis Rockman)
        Speaker at Biodiversity in Four Dimensions symposium, Stanford University, April 8
        2010
        Presenter at Night Life series, California Academy of Sciences, San Francisco, CA
        2009
        International Conference on Biodiversity Informatics, London, UK, use of images for conference materials
        2008
        Presenter at Bioneers annual conference, San Rafael, CA
        TAXA, limited-edition suite of prints, published by Feature Inc., New York, NY
        Taxa, monograph with texts by Edward O. Wilson and Susan Emerling, published by Feature Inc., New York, NY
        Cover art for National Academies Press seasonal book catalog
        2007
        Presenter in conjunction with EO Wilson, TED, Monterrey, CA
        2006
        Illustrations for Art in Action: Nature, Creativity and Our Collective Future, The Natural World Museum & UNEP, Earth Aware Editions, San Rafael, CA
        Reproductions in Éco-Art by Catherine Merckling, Université Marc Bloch, Strasbourg
        Call of Life: Facing the Mass Extinction (reproductions of artwork), Film by Species Alliance
        2005
        Reproductions in Tricycle: The Buddhist Review, Summer
        American Butterflies 13, no. 1 (Spring), pp. 44-45 (reproduction and artist’s statement)
        2003
        Portfolio in Grand Street, no. 71 (Spring), Grand Street Press, New York, NY, pp. 92-96
        2002
        Cover art for The Future of Life by E. O. Wilson, Knopf, New York, NY
        2000
        Covers for Whole Earth Catalog front and back covers, Fall
        
        Selected Awards
        2008
        Individual Artist Grant, Wynn Newhouse
        2004
        Individual Artist Grant, George Sugarman Foundation
        Individual Artist Grant, Marin Arts Council
        
        Public Collections
        Art, Design and Architecture Museum, University of California, Santa Barbara, CA
        Berkeley Art Museum and Pacific Film Archive, Berkeley, CA
        Chazen Museum of Art, University of Wisconsin, Madison, WI
        Davis Museum, Wellesley College, Wellesley, MA
        The Frances Lehman Loeb Art Center, Vassar College, Poughkeepsie, NY
        Hammer Museum, University of California, Los Angeles, CA
        The Hood Museum of Art, Dartmouth College, Hanover, NH
        Missouri Botanic Gardens, St Louis, MO
        New York Public Library, New York, NY
        Pennsylvania Academy of Fine Arts, Philadelphia, PA
        Picker Art Gallery, Colgate University, Hamilton, NY
        Queens Museum, Queens, NY
        Rhode Island School of Design, Providence, RI
        Richmond Center for Visual Arts, Western Michigan University, Kalamazoo, MI
        Rockefeller University, New York, NY
        San Francisco Museum of Modern Art, San Francisco, CA
        Spenser Museum of Art, University of Kansas, Lawrence, KS
        St. Louis Museum of Art, St. Louis, MO
        Tang Museum, Skidmore College, Saratoga Springs, NY
        The Garrison Institute, Garrison, NY
        The Toledo Art Museum, Toledo, OH
        University of Minnesota, Minneapolis, MN
        University Museum of Contemporary Art, University of Massachusetts, Amherst, MA
        Vera List Center, The New School, New York, NY
        Whitney Museum of American Art, New York, NY
        Williams College Museum of Art, Williamstown, MA
        Yale University Art Gallery, New Haven, CT
        Zimmerli Art Museum, Rutgers University, New Brunswick, NJ`,
        image: '',
        type: 'page',
        path: '/curriculum-vitae'
    },
    {
        name: 'Statement & Acknowledgments',
        slug: 'statement-and-acknowledgments',
        description: `Statement & Acknowledgments
        Accurate depiction of plants and animals has served the biological sciences for centuries. Once Carl Linnaeus built his taxonomic system to classify and organize species, the binomial Latin system still used, scientific illustration became an essential tool of accurate diagnostic differentiation.
        
        I choose to apply accuracy in a different service: my analog and intentionally durable works in oil reflect selected flora and fauna of earth that may well outlast the species depicted in them. Especially for highly evolved and niche dependent species, the paintings may serve as witness to their loss.
        
        While this is often called the digital age, our formats are increasingly ephemeral. Operating systems do not communicate with each other across time. However, oil paintings can endure centuries, like a time capsule. These paintings are a hedge against a future distant enough, or even near enough, to lack both the plants and animals in them, and the means to retrieve their images.
        
        Deep detail is one way to slow people down from the warp speed and distortion of modern life. In that slower, quieter moment, people may feel connected to the painted species in front of them and reflect upon their own impact on them. Humans may cause or prevent a species' survival or extinction through how we live our lives, what we eat, what we choose as work, and how we spend our money. We are not separate from nature, but a disproportionately large part of it. As the dominant species on this planet, we have an incumbent responsibility to the rest of its life.
        
        My paintings present nature as a rich and varied tapestry while the actual species of plants and animals that form the warp and weft threads of natures' fabric disappear at unprecedented rates, from place to place, bit by bit. Each generation of humans has a warped view of biological diversity; we are blind to slow, incremental change and cannot see the species richness of our grandparents' time. Nature in our own time seems rich and varied to us, particularly since we spend less and less time outside in it.
        
        There can be no return to some mythic time long ago when things were golden, nature is in a constant state of change. But we must find ways to allow nature itself the time and space to repair some of the damage mankind has caused. To avoid the correction that ecologists usually see when an opportunistic species has a population boom and surge in resource use, an equally large die-off, nature's systems must remain functional: water and carbon cycles, ocean currents, soil health, boreal and tropical forests, etc. We must keep and regain as much species richness and diversity as possible, everywhere.
        
        And for that to happen, more of us must care.
        
        Isabella Kirkland
        2024
        
        
        Photo: Christoper Michel
        
        Acknowledgements
        Research for my work takes me to museums and institutions far and wide. Scientists, from many disciplines, have answered my questions, shared their expertise, and granted me access to museums, zoos, collections, libraries, and experiences. Other artists past and present, photographers, and scientific illustrators have contributed to my perception of particular plants and animals. Thank you.
        
        I am very grateful to my home institution, The California Academy of Sciences. CAS has allowed me to be part of its community and to conduct my own research in its collections since I first began this biodiversity-based work in the early 1990’s.
        
        All who have bought that work over the years have funded my continued documentation of some of Earth’s rare flora and fauna and my activism on behalf of species that cannot advocate for themselves. You hold the paintings safely, to help them move into the future, to continue their advocacy.
        
        My work would not be possible without a wide network of friends: Renny Pritikin, Maria Porges, Ryan Phelan, Stewart Brand, and Julia Kirkland Berger provide a deep well of friendship and support that has helped all of this work grow and evolve. Your continued faith is mana. Chris Tellis is my stalwart supporter, cheering section, and husband. Our son, Nate Tellis, is a gentle, yet astute critic. To those unnamed, know that you are deeply appreciated.
        
        IK, 2024
        `,
        image: '/img/isabella-kirkland-portrait-2.jpg',
        type: 'page',
        path: '/statement-and-acknowledgements'
    },
    {
        name: 'Process',
        slug: 'process',
        description: `Process Time-lapse

        From beginning of research to the final details, large complex paintings like this one take about a year to complete. The first iteration is a database of species that fit the subject of the painting. Next come separate life-sized drawings on tracing paper of each species in the database. The 4 or 5 largest and most dominant of these can be rearranged until a pleasing composition suggests itself, one that might follow an S, C, or X form using a branch, vase, or stairs, something that keeps the eye moving from shape to shape. All the plants and animals from the list are fitted into that overall movement: each species redrawn in a position appropriate to the underlying structure. A final cartoon is transferred to a prepared background. Then each plant or animal must be painted correctly, in concert and harmony with its neighbors, while inferring the light, distance, and air around it.
        
        IK, 2024`,
        image: '/img/process-small.gif',
        type: 'page',
        path: '/process'
    },
]
